export default {
  // Page titles
  homeTitle: 'Mediabay - Асосӣ',

  // Common
  oldSite: 'Сомонаи пешина',
  pageError: 'Дар барнома иштибоҳ шуд',
  pageNotFound: 'Саҳифа ёфт нашуд',
  returnToHome: 'Бозгашт ба асосӣ',
  loadingError: 'Иштибоҳ  дар пуркунӣ. Кушиш кунед, ки саҳифаро нав намоед',
  tvPlayer: 'ТВ бозингар',
  watch: 'Тамошо',
  watchAll: 'Тамошои ҳамааш',
  buy: 'Харид',
  active: 'Фаъол',
  copyrightUz: 'ҶДММ “Mediabay Asia”',
  copyrightTv: '"International Mediabay Alliance" LLC',
  policy: 'Сиёсати махфият',
  yourIp: 'IP-и Шумо:',
  yourCountry: 'Кишвар:',
  connection: 'Навъи пайвастшави',
  noSearchData: 'Аз рӯи дархости шумо чизе ёфт нашуд',
  watchEverywhere: 'Mediabay-ро дар ҳамагуна таҷҳизотҳо тамошо намоед',
  watchDevices: 'Smart TV, iPad ё iPhone, смартфонҳо ва планшетҳои Android, компютерҳо ва ноутбукҳо',
  allChannels: 'Тамоми шабакаҳо',
  details: 'Гузаштан',
  episodes: 'Лаҳзаҳо',
  filterLabel: 'Полоиш',
  filterReset: 'тоза (пок) кардан',
  moviesGenres: 'Жанри филмҳо',
  serialsGenres: 'Жанри сериалҳо',
  podcastsGenres: 'Жанри барномаҳо',
  clipsGenres: 'Жанри клипҳо',
  musicGenres: 'Жанри мусиқӣ',
  cartoonsGenres: 'Жанри филмҳои тасвирӣ',
  audiobooksGenres: 'Жанри аудиокитобҳо',
  country: 'Кишвар',
  releaseYear: 'Сол',
  language: 'Забон',

  // Not available data
  noData: 'Маълумот барои инъикос нест',
  noNotificationsData: 'Хабарҳо нест',
  noChannelsData: 'Бор кардани канал имконият надод',
  noMediaData: 'Бор кардани маълумотҳо имконият надод',
  noGuideData: 'Ҳозир телебарномаҳои дастрас нест',
  noTransactions: 'Амалиёт нест',

  // Sections
  home: 'Асосӣ',
  tv: 'Тв-мустақим',
  radio: 'Радио',
  newMovies: 'Синамоҳои навин',
  movies: 'Филмҳо',
  serials: 'Сериалҳо',
  cartoons: 'Филмҳои тасвирӣ',
  podcasts: 'Теленамоиш',
  clips: 'Клипҳо',
  music: 'Мусиқӣ',
  audioBooks: 'Аудиокитоб',
  favorites: 'Ба интихобшуда',
  apps: 'Боркунии замима',

  // TV Guide
  tvGuide: 'Барномаҳои ТВ',
  monday: 'Дш',
  tuesday: 'Сш',
  wednesday: 'Чш',
  thursday: 'Пш',
  friday: 'Чм',
  saturday: 'Шн',
  sunday: 'Яш',
  live: 'Дар эфир',
  nextItem: 'ДаМинбаъдлее',
  evenLater: 'Боз дертар',

  // Content genres
  freeTv: 'Телеканалҳои ройгон',
  premiumTv: 'Телеканалҳои пулакӣ',
  freeRadio: 'Радиостансияҳо',
  new: 'Навинҳо',
  last: 'Иловашудаҳои охирин',
  top: 'Машҳур',
  recommended: 'Тавсияшаванда',

  // Auth
  authEnter: 'Воридшавӣ',
  authReg: 'Ба қайд гузоштан',
  rulesWarning: 'Тугмачаи “Бақайдгирӣ”-ро пахш намуда шумо қабул менамоед ',
  signIn: 'Воридшавӣ',
  signUp: 'Бақайдгирӣ',
  authAction: 'Ворид шавед ё аз қайд гузаред',
  name: 'Номи шумо',
  email: 'Email-и  шумо',
  emailOrLogin: 'Email ё логин',
  login: 'Логин',
  password: 'Парол',
  confirmPassword: 'Тасдиқи парол',
  needEmailConfirm: 'Бақайдгирӣ бомуваффақона гузашт, тасдиқнома ба email-и Шумо равон гардид',
  successfullyConfirm: 'Еmail бомуваффақона тасдиқ гардид',
  successfullyValidated: 'Парол тасдиқ гардид, бо пароли нав авторизатсия шавед',
  errorConfirm: 'Хатогии фаъолшавии email',
  errorValidate: 'Хатогии фаъолшавии пароли нав',
  continue: 'Идомадиҳӣ',
  save: 'Маҳфузнамоӣ',
  forgotPassword: 'Паролро фаромуш намудед?',
  resendConfirmEmail: 'Отправить письмо подтверждения повторно',

  // User
  logIn: 'Воридшавӣ',
  logOut: 'Барориш',
  id: 'ID-и  Шумо:',
  balance: 'Тавозуни Шумо:',
  expired: 'Обуна:',
  notActiveVoucher: 'фаъол нест',
  profileData: 'Утоқи шахсӣ',
  settings: 'Чурсозӣ',
  subscribeChannelInfo: 'Барои тамошои канали пулакӣ, обунаро фаъол намоед',
  subscribeAction: 'Фаъол намудани обуна',
  profileFavorites: 'Ба интихобшуда',
  profileNoFavoritesData: 'Шумо ҳеҷ чиз ба интихобшуда илова нанамудед',
  profilePay: 'Пуркунии тавозун',
  profilePaymentLabel: 'Маблағи пуркунӣ',
  profilePaymentService: 'Хизматрасониҳо ва нархномаҳо',
  profileServices: 'Хизматрасонӣ ва нархнома',
  ProfilePayAndBuy: 'Оплата и подписка',
  ProfilePayAndBuyForTv: 'Оплата',
  profileTransactions: 'Амалиётҳои ман',
  profileTransactionsDate: 'Сана',
  profileTransactionsDescr: 'Тавсиф',
  profileTransactionsAmount: 'Маблағ',
  profileSettings: 'Ҷӯрсозиҳои ман',
  profileSettingsUpdateSuccess: 'Ҷӯрсозиҳо муввафақона нав шуданд',

  // Footer
  about: 'Оид ба хизматрасонӣ',
  licenses: 'Иҷозатнома',
  rules: 'Созишномаи истифодабарӣ',
  contacts: 'Тамос бо мо',

  // PROMO
  promoCodeTitle: 'Активация промокода',
  promoCodeDescription: 'Активируйте Ваш промокод и получите доступ ко всем телеканалам',
  promoCodeHint: 'Введите промокод',
  promoCodeActivate: 'Активировать',
  promoCodeSuccessText: 'Ваш промокод успешно активирован',
  promoCodeCallToWatch: 'Начать просмотр',
};
