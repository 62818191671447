import React from 'react';
import styled from 'styled-components';
import Icon from 'components/mediabay-ui/components/Icon';
import { Link } from 'react-router-dom';
import { rem } from 'polished';

const Button = styled.button`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 10px 20px;
  font-size: ${rem('16px')};
  text-decoration: none;
  color: #fff;
  cursor: pointer;
  background: transparent;
  border: 0;
  font-family: inherit;

  &:hover {
    background: #111;
  }
`;

const ExternalLink = styled.a`
  display: block;
  padding: 10px;
  padding-left: 65px;
  font-size: 14px;
`;

const SchoolLink = styled(Link)`
  display: block;
  padding: 10px;
  padding-left: 65px;
`;

class MenuItemExpandable extends React.Component {
  constructor(props) {
    super();

    this.state = {
      isActive: false,
    }

    this.handleClick = this.handleClick.bind(this);
  }


  handleClick() {
    this.setState(prevState => {
      this.setState({
        isActive: !prevState.isActive
      })
    })
  }

  render() {
    const { isActive } = this.state;

    return (
      <React.Fragment>
        <Button onClick={this.handleClick}>
          <Icon
            icon='education'
            style={{ margin: '0 10px 0 5px', width: 20, padding: '0 5px' }}
          />
          <span>Онлайн школа</span>
        </Button>
        <div style={{ display: isActive ? 'block' : 'none' }}>
          <ExternalLink style={{ color: '#fff' }} href="/guide.pdf" target="_blank">
            Инструкция
          </ExternalLink>
          <ExternalLink style={{ color: '#fff' }} href="/schedule-uz.pdf" target="_blank">
            Расписание(09.04.2020)
          </ExternalLink>
          <SchoolLink to="/tv/397/class-1-4">
            1-4 классы
          </SchoolLink>
          <SchoolLink to="/tv/398/class-5-8">
            5-8 классы
          </SchoolLink>
          <SchoolLink to="/tv/400/class-9-11">
            9-11 классы
          </SchoolLink>
        </div>
      </React.Fragment>
    );
  }
}

export default MenuItemExpandable;
