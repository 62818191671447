import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const LogoWrapper = styled.span`
  display: flex;
  align-items: center;
`;

const Logo = ({ logoSrc, altText, height }) => (
  <LogoWrapper>
    <img src={logoSrc} alt={altText} height={height} />
  </LogoWrapper>
);

Logo.propTypes = {
  logoSrc: PropTypes.string.isRequired,
  altText: PropTypes.string.isRequired,
  /** Pixels of height */
  height: PropTypes.number,
};

Logo.defaultProps = {
  height: 25,
};

export default Logo;
