import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import theme from 'styled-theming';

const StyledDropdown = styled.div`
  position: relative;
  display: inline-block;
`;

const DropdownContent = styled.div`
  position: absolute;
  top: 150%;
  right: -10%;
  display: ${props => (props.isOpen ? 'block' : 'none')};
  background: ${theme('mode', { light: '#2c2e36', dark: '#2c2e36' })};
  border-radius: 5px;
  box-shadow: 0 -5px 40px 10px rgba(0, 0, 0, .3);
  max-width: 300px;

  &:before {
    content: '';
    position: absolute;
    bottom: 100%;
    right: 20px;
    width: 0;
    height: 0;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;

    border-bottom: 12px solid #2c2e36;
  }
`;

class Dropdown extends React.Component {
  static defaultProps = {
    triggerStyles: {},
  }

  static propTypes = {
    children: PropTypes.node.isRequired,
    trigger: PropTypes.node.isRequired,
    triggerStyles: PropTypes.objectOf(PropTypes.any),
  }

  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };

    this.btnClickHandler = this.btnClickHandler.bind(this);
    this.hideDropdown = this.hideDropdown.bind(this);
    this.onWindowClick = this.onWindowClick.bind(this);
  }

  componentDidMount() {
    window.addEventListener('click', this.onWindowClick);
    window.addEventListener('touchstart', this.onWindowClick);
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.onWindowClick);
    window.removeEventListener('touchstart', this.onWindowClick);
  }

  onWindowClick(event) {
    if (
      this.state.isOpen &&
      !this.dropdown.contains(event.target)
    ) {
      this.setState({
        isOpen: false,
      });
    }
  }

  btnClickHandler() {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }

  hideDropdown() {
    setTimeout(() => {
      this.setState({
        isOpen: false,
      });
    }, 100);
  }

  render() {
    return (
      <StyledDropdown ref={(dropdown) => { this.dropdown = dropdown; }}>
        <button
          onClick={this.btnClickHandler}
          style={{
            display: 'flex',
            justifyContent: 'center',
            cursor: 'pointer',
            background: 'transparent',
            border: 0,
            outline: 0,
            ...this.props.triggerStyles,
          }}
        >
          {this.props.trigger}
        </button>
        <DropdownContent
          isOpen={this.state.isOpen}
          onBlur={this.hideDropdown}
        >
          {this.props.children}
        </DropdownContent>
      </StyledDropdown>
    );
  }
}

export default Dropdown;
