/* eslint-disable jsx-a11y/media-has-caption */
import React from 'react';
import PropTypes from 'prop-types';
import IconButton from 'components/mediabay-ui/components/IconButton';
import Icon from 'components/mediabay-ui/components/Icon';
import {
  MainContainer,
  Row,
  MetaContainer,
  ControlsContainer,
} from './styles';

export default class RadioPlayer extends React.Component {
  static propTypes = {
    clearRadioPlayer: PropTypes.func.isRequired,
    updateSelectedIndex: PropTypes.func.isRequired,
    data: PropTypes.shape({
      selectedIndex: PropTypes.number,
      channels: PropTypes.arrayOf(PropTypes.object),
    }).isRequired,
  }

  constructor(props) {
    super(props);

    this.state = {
      selectedIndex: this.props.data.selectedIndex,
      indexUpdated: false,
    };

    this.refreshPlayerSources = this.refreshPlayerSources.bind(this);
    this.prevClickHandler = this.prevClickHandler.bind(this);
    this.playPauseClickHandler = this.playPauseClickHandler.bind(this);
    this.nextClickHandler = this.nextClickHandler.bind(this);
  }

  componentDidMount() {
    const { channels } = this.props.data;
    const { selectedIndex } = this.state;
    this.player = window.videojs('audio-player', {
      sources: {
        src: channels[selectedIndex].preview,
        type: 'application/x-mpegURL',
      },
      autoplay: true,
      controls: false,
      height: '40px',
      width: '100%',
    });
  }

  componentDidUpdate() {
    this.refreshPlayerSources();
  }

  // destroy player on unmount
  componentWillUnmount() {
    if (this.player) {
      this.player.dispose();
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.data.selectedIndex !== prevState.selectedIndex) {
      return {
        selectedIndex: nextProps.data.selectedIndex,
        indexUpdated: true,
      };
    }

    return null;
  }

  refreshPlayerSources() {
    const { channels } = this.props.data;
    const { selectedIndex } = this.state;

    if (this.state.indexUpdated) {
      this.player.src([{
        src: channels[selectedIndex].preview,
        type: 'application/x-mpegURL',
      }]);
      this.player.play();
      this.setState({
        indexUpdated: false,
      });
    }
  }

  prevClickHandler() {
    const { channels } = this.props.data;
    const { selectedIndex } = this.state;
    const index = selectedIndex !== 0 ? selectedIndex - 1 : channels.length - 1;
    this.props.updateSelectedIndex(index);
  }

  playPauseClickHandler() {
    this.setState({
      isRadioPlaying: !this.player.paused(),
    });

    if (this.player.paused()) {
      this.player.play();
    } else {
      this.player.pause();
    }
  }

  nextClickHandler() {
    const { channels } = this.props.data;
    const { selectedIndex } = this.state;
    const index = (selectedIndex + 1) % channels.length;
    this.props.updateSelectedIndex(index);
  }

  render() {
    const { isRadioPlaying } = this.state;
    const { data } = this.props;

    return (
      <MainContainer {...this.props}>
        <Row>
          <MetaContainer id="meta-container">
            <img
              src={
                `${data.mediaHost}${data.channels[data.selectedIndex].logo.replace(/.png|.jpg|.jpeg/, match => `.100${match}`)}`
              }
              alt={data.channels[data.selectedIndex].name}
            />
          </MetaContainer>
          <p
            style={{
              margin: '0 15px',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              textAlign: 'center',
            }}
          >
            {data.channels[data.selectedIndex].name}
          </p>
          <ControlsContainer id="control-container">
            <IconButton
              icon="prev"
              width={16}
              height={16}
              onClick={this.prevClickHandler}
            />

            <IconButton
              icon={isRadioPlaying ? 'play' : 'pause'}
              width={16}
              height={22}
              style={{ margin: '0 5px' }}
              onClick={this.playPauseClickHandler}
            />

            <IconButton
              icon="next"
              width={16}
              height={16}
              onClick={this.nextClickHandler}
            />
          </ControlsContainer>
          <Icon
            icon="close"
            width={14}
            height={14}
            onClick={this.props.clearRadioPlayer}
            style={{
              position: 'absolute',
              top: 0,
              left: '100%',
              padding: 0,
              border: 0,
              cursor: 'pointer',
            }}
          />
        </Row>

        <div data-vjs-player style={{ display: 'none' }}>
          <audio id="audio-player" />
        </div>
      </MainContainer>
    );
  }
}
