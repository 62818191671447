import axios from 'axios';

import {
  CHANGE_SIDEBAR_VISIBILITY,
  FORCE_SIDEBAR_VISIBILITY,
  REGISTER_FCM_TOKEN,
  RECIVE_NOTIFICATION,
} from '../constants';

export const sidebarCollapsed = payload => ({
  type: CHANGE_SIDEBAR_VISIBILITY,
  payload,
});

export const forceSidebarCollapse = payload => ({
  type: FORCE_SIDEBAR_VISIBILITY,
  payload,
});

export const fcmRegisterTokenSuccess = response => ({
  type: REGISTER_FCM_TOKEN,
  response,
});

export const fcmRegisterToken = token => dispatch => (
  axios.get(`/notification/savetoken?token=${token}`)
    .then((response) => {
      const data = {
        status: response.status,
        data: response.data.data,
      };
      return dispatch(fcmRegisterTokenSuccess(data));
    })
    .catch((error) => {
      const data = {
        status: 0,
        data: [],
      };
      if (error.response) {
        data.status = error.response.status;
      } else if (error.request) {
        data.status = 0;
      } else {
        data.status = 0;
      }
      dispatch(fcmRegisterTokenSuccess(data));
    })
);

export const reciveNotification = response => ({
  type: RECIVE_NOTIFICATION,
  response,
});
