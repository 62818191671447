import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import theme from 'styled-theming';
import Icon from 'components/mediabay-ui/components/Icon';

const StyledAvatar = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: ${theme('mode', { light: '#2c2f36', dark: '#2c2f36' })};
  color: #fff;
  overflow: hidden;
  width: 35px;
  height: 35px;
  border: 1.5px solid #555;
`;

const Avatar = props => (
  <StyledAvatar src={props.src}>
    {
      props.src ? (
        <img src={props.src} alt="Avatar" />
      ) : <Icon icon={props.icon} width={20} height={20} color="#fff" />
    }
  </StyledAvatar>
);

Avatar.defaultProps = {
  src: null,
  icon: 'user',
};

Avatar.propTypes = {
  src: PropTypes.string,
  icon: PropTypes.string,
};

export default Avatar;
