import initialState from './initialState';
import {
  GET_MAIN_SLIDES,
  GET_MOVIES_SLIDES,
  GET_SERIALS_SLIDES,
  GET_CARTOONS_SLIDES,
  GET_PODCASTS_SLIDES,
  GET_CLIPS_SLIDES,
  GET_TV_SLIDES,
  GET_RADIO_SLIDES,
} from '../constants';

export default (state = initialState.sliders, action) => {
  switch (action.type) {
    case GET_MAIN_SLIDES:
      return { ...state, main: action.response };

    case GET_TV_SLIDES:
      return { ...state, tv: action.response };

    case GET_RADIO_SLIDES:
      return { ...state, radio: action.response };

    case GET_MOVIES_SLIDES:
      return { ...state, movies: action.response };

    case GET_SERIALS_SLIDES:
      return { ...state, serials: action.response };

    case GET_CARTOONS_SLIDES:
      return { ...state, cartoons: action.response };

    case GET_PODCASTS_SLIDES:
      return { ...state, podcasts: action.response };

    case GET_CLIPS_SLIDES:
      return { ...state, clips: action.response };

    default:
      return state;
  }
};
