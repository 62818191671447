import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'components/mediabay-ui/components/Icon';
import styled from 'styled-components';
import theme from 'styled-theming';

const StyledButton = styled.button`
  background: transparent;
  color: ${theme('mode', { light: '#000', dark: '#fff' })};
  border: 0;
  padding: 0;
  outline: 0;
  cursor: pointer;
`;

class IconButton extends React.Component {
  static defaultProps = {
    color: '#fff',
    onClick: null,
    width: 24,
    height: 24,
    style: {},
  }

  static propTypes = {
    icon: PropTypes.string.isRequired,
    color: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.func,
    ]),
    onClick: PropTypes.func,
    width: PropTypes.number,
    height: PropTypes.number,
    style: PropTypes.objectOf(PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ])),
  }

  render() {
    const {
      icon,
      style,
      color,
      onClick,
    } = this.props;

    return (
      <StyledButton onClick={onClick} {...this.props}>
        <Icon
          icon={icon}
          width={this.props.width}
          height={this.props.height}
          color={color}
          style={style}
        />
      </StyledButton>
    );
  }
}

export default IconButton;
