import axios from 'axios';
import { GET_SEARCH_CONTENT, RESET_SEARCH_CONTENT } from '../constants';

export const resetSearchData = () => ({
  type: RESET_SEARCH_CONTENT,
});

export const getSearchDataSuccess = response => ({
  type: GET_SEARCH_CONTENT,
  response,
});

export const getSearchData = query => dispatch => (
  axios.get(`/search?q=${query}`)
    .then((response) => {
      const data = {
        status: response.status,
        data: response.data.data,
      };
      return dispatch(getSearchDataSuccess(data));
    })
    .catch((error) => {
      const data = {
        status: 0,
        data: [],
      };
      if (error.response) {
        data.status = error.response.status;
      } else if (error.request) {
        data.status = 0;
      } else {
        data.status = 0;
      }
      dispatch(getSearchDataSuccess(data));
    })
);
