import React from 'react';

const AdBlockPage = () => (
  <div
    style={{
      background: '#000',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      color: '#fff',
      display: 'flex',
      justifyItems: 'center',
      flexDirection: 'column',
    }}
  >
    <div style={{ padding: '15px 30px' }}>
      <h2>Похоже вы используете блокировщик рекламы</h2>
      <p>Для того, чтобы продолжить использовать наш сайт</p>
      <p>Вам нужно отключить блокировщик рекламы или добавить наш сайт в список исключений</p>
    </div>
  </div>
);

export default AdBlockPage;
