import initialState from './initialState';
import {
  GET_CLIPS_FILTER,
  GET_FILTERED_CLIPS,
  CLEAR_FILTERED_CLIPS,
  GET_CLIP,
  GET_CLIP_THREAD,
  CLEAR_CLIP_DATA,
  GET_NEW_CLIPS,
  GET_LAST_CLIPS,
  GET_TOP_CLIPS,
  GET_RECOMMENDED_CLIPS,
} from '../constants';

export default (state = initialState.clips, action) => {
  switch (action.type) {
    case GET_CLIPS_FILTER:
      return {
        ...state,
        availableFilters: action.response,
      };

    case GET_FILTERED_CLIPS:
      return {
        ...state,
        filteredData: {
          status: action.response.status,
          pageLoaded: action.response.pageLoaded,
          hasNext: action.response.hasNext,
          data: state.filteredData.data.concat(action.response.data),
        },
      };

    case CLEAR_FILTERED_CLIPS:
      return {
        ...state,
        filteredData: {
          status: null,
          pageLoaded: 1,
          hasNext: true,
          data: [],
        },
      };

    case GET_CLIP:
      return {
        ...state,
        clip: action.response,
      };

    case GET_CLIP_THREAD:
      return {
        ...state,
        clipThread: action.response,
      };

    case CLEAR_CLIP_DATA:
      return {
        ...state,
        clip: {
          status: null,
          data: {},
        },
        clipThread: {
          status: null,
          data: {},
        },
      };

    case GET_NEW_CLIPS:
      return {
        ...state,
        new: {
          status: action.response.status,
          pageLoaded: action.response.pageLoaded,
          hasNext: action.response.hasNext,
          data: state.new.data.concat(action.response.data),
        },
      };

    case GET_LAST_CLIPS:
      return {
        ...state,
        last: {
          status: action.response.status,
          pageLoaded: action.response.pageLoaded,
          hasNext: action.response.hasNext,
          data: state.last.data.concat(action.response.data),
        },
      };

    case GET_TOP_CLIPS:
      return {
        ...state,
        top: {
          status: action.response.status,
          pageLoaded: action.response.pageLoaded,
          hasNext: action.response.hasNext,
          data: state.top.data.concat(action.response.data),
        },
      };

    case GET_RECOMMENDED_CLIPS:
      return {
        ...state,
        recommended: {
          status: action.response.status,
          pageLoaded: action.response.pageLoaded,
          hasNext: action.response.hasNext,
          data: state.recommended.data.concat(action.response.data),
        },
      };

    default:
      return state;
  }
};
