import initialState from './initialState';
import { GET_ARTICLE } from '../constants';

export default (state = initialState.article, action) => {
  switch (action.type) {
    case GET_ARTICLE:
      return {
        ...state,
        ...action.response,
      }

    default:
      return state;
  }
};
