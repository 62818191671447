import { createStore, applyMiddleware, compose } from 'redux';
import { routerMiddleware } from 'connected-react-router'
import thunk from 'redux-thunk';
import { createBrowserHistory } from 'history';
import rootReducer from '../reducers';

export const history = createBrowserHistory();
const enhancers = [];
const middleware = [
  thunk,
  routerMiddleware(history),
];


if (process.env.NODE_ENV === 'development') {
  /* eslint-disable prefer-destructuring */
  const devToolsExtension = window.devToolsExtension;

  if (typeof devToolsExtension === 'function') {
    enhancers.push(devToolsExtension());
  }
}

const composedEnhancers = compose(
  applyMiddleware(...middleware),
  ...enhancers,
);

export default createStore(
  rootReducer(history),
  {},
  composedEnhancers,
);
