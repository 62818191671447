// Constants of redux actions //
// ========================== //

// Common
export const CHANGE_SIDEBAR_VISIBILITY = 'CHANGE_SIDEBAR_VISIBILITY';
export const FORCE_SIDEBAR_VISIBILITY = 'FORCE_SIDEBAR_VISIBILITY';
export const REGISTER_FCM_TOKEN = 'REGISTER_FCM_TOKEN';
export const RECIVE_NOTIFICATION = 'RECIVE_NOTIFICATION';

// User
export const GET_INITIAL_USER_INFO = 'GET_INITIAL_USER_INFO';
export const CHECK_EMAIL = 'CHECK_EMAIL';
export const CONFIRM_USER_EMAIL = 'CONFIRM_USER_EMAIL';
export const USER_SIGN_UP = 'USER_SIGN_UP';
export const USER_SIGN_IN = 'USER_SIGN_IN';
export const USER_SIGN_OUT = 'USER_SIGN_OUT';
export const GET_USER_TRANSACTIONS = 'GET_USER_TRANSACTIONS';
export const GET_AVAILABLE_VOUCHERS = 'GET_AVAILABLE_VOUCHERS';
export const UPDATE_USER_DATA = 'UPDATE_USER_DATA';

// Favorites
export const GET_FAVORITES = 'GET_FAVORITES';

// Articles
export const GET_ARTICLE = 'GET_ARTICLE';

// TV
export const GET_TV_CHANNEL = 'GET_TV_CHANNEL';
export const GET_TV_THREAD = 'GET_TV_THREAD';
export const GET_TV_CHANNELS = 'GET_TV_CHANNELS';

// Channel guide
export const GET_CHANNEL_GUIDE = 'GET_CHANNEL_GUIDE';
export const GET_CHANNELS_GUIDE = 'GET_CHANNELS_GUIDE';
export const GET_WEEKDAY_CHANNEL_GUIDE = 'GET_WEEKDAY_CHANNEL_GUIDE';

// Radio
export const GET_RADIO_CHANNELS = 'GET_RADIO_CHANNELS';
export const SET_RADIO_PLAYER = 'SET_RADIO_PLAYER';
export const CHANGE_RADIO_CHANNEL = 'CHANGE_RADIO_CHANNEL';
export const CLEAR_RADIO_PLAYER = 'CLEAR_RADIO_PLAYER';

// Movies
export const GET_MOVIES_FILTER = 'GET_MOVIES_FILTER';
export const GET_FILTERED_MOVIES = 'GET_FILTERED_MOVIES';
export const CLEAR_FILTERED_MOVIES = 'CLEAR_FILTERED_MOVIES';
export const GET_MOVIE = 'GET_MOVIE';
export const GET_MOVIE_EPISODES = 'GET_MOVIE_EPISODES';
export const GET_MOVIE_THREAD = 'GET_MOVIE_THREAD';
export const CLEAR_MOVIE_DATA = 'CLEAR_MOVIE_DATA';
export const GET_NEW_MOVIES = 'GET_NEW_MOVIES';
export const GET_LAST_MOVIES = 'GET_LAST_MOVIES';
export const GET_TOP_MOVIES = 'GET_TOP_MOVIES';
export const GET_RECOMMENDED_MOVIES = 'GET_RECOMMENDED_MOVIES';

// Serials
export const GET_SERIALS_FILTER = 'GET_SERIALS_FILTER';
export const GET_FILTERED_SERIALS = 'GET_FILTERED_SERIALS';
export const CLEAR_FILTERED_SERIALS = 'CLEAR_FILTERED_SERIALS';
export const GET_SERIAL = 'GET_SERIAL';
export const GET_SERIAL_EPISODES = 'GET_SERIAL_EPISODES';
export const GET_SERIAL_THREAD = 'GET_SERIAL_THREAD';
export const CLEAR_SERIAL_DATA = 'CLEAR_SERIAL_DATA';
export const GET_NEW_SERIALS = 'GET_NEW_SERIALS';
export const GET_LAST_SERIALS = 'GET_LAST_SERIALS';
export const GET_TOP_SERIALS = 'GET_TOP_SERIALS';
export const GET_RECOMMENDED_SERIALS = 'GET_RECOMMENDED_SERIALS';

// Cartoons
export const GET_CARTOONS_FILTER = 'GET_CARTOONS_FILTER';
export const GET_FILTERED_CARTOONS = 'GET_FILTERED_CARTOONS';
export const CLEAR_FILTERED_CARTOONS = 'CLEAR_FILTERED_CARTOONS';
export const GET_CARTOON = 'GET_CARTOON';
export const GET_CARTOON_EPISODES = 'GET_CARTOON_EPISODES';
export const GET_CARTOON_THREAD = 'GET_CARTOON_THREAD';
export const CLEAR_CARTOON_DATA = 'CLEAR_CARTOON_DATA';
export const GET_NEW_CARTOONS = 'GET_NEW_CARTOONS';
export const GET_LAST_CARTOONS = 'GET_LAST_CARTOONS';
export const GET_TOP_CARTOONS = 'GET_TOP_CARTOONS';
export const GET_RECOMMENDED_CARTOONS = 'GET_RECOMMENDED_CARTOONS';

// Audiobooks
export const GET_AUDIOBOOKS_FILTER = 'GET_AUDIOBOOKS_FILTER';
export const GET_FILTERED_AUDIOBOOKS = 'GET_FILTERED_AUDIOBOOKS';
export const CLEAR_FILTERED_AUDIOBOOKS = 'CLEAR_FILTERED_AUDIOBOOKS';
export const GET_AUDIOBOOK = 'GET_AUDIOBOOK';
export const GET_AUDIOBOOK_THREAD = 'GET_AUDIOBOOK_THREAD';
export const CLEAR_AUDIOBOOK_DATA = 'CLEAR_AUDIOBOOK_DATA';
export const GET_NEW_AUDIOBOOKS = 'GET_NEW_AUDIOBOOKS';
export const GET_LAST_AUDIOBOOKS = 'GET_LAST_AUDIOBOOKS';
export const GET_TOP_AUDIOBOOKS = 'GET_TOP_AUDIOBOOKS';
export const GET_RECOMMENDED_AUDIOBOOKS = 'GET_RECOMMENDED_AUDIOBOOKS';

// Music
export const GET_MUSIC_FILTER = 'GET_MUSIC_FILTER';
export const GET_FILTERED_MUSIC = 'GET_FILTERED_MUSIC';
export const CLEAR_FILTERED_MUSIC = 'CLEAR_FILTERED_MUSIC';
export const GET_ALBUM = 'GET_ALBUM';
export const GET_MUSIC_THREAD = 'GET_MUSIC_THREAD';
export const CLEAR_MUSIC_DATA = 'CLEAR_MUSIC_DATA';
export const GET_NEW_MUSIC = 'GET_NEW_MUSIC';
export const GET_LAST_MUSIC = 'GET_LAST_MUSIC';
export const GET_TOP_MUSIC = 'GET_TOP_MUSIC';
export const GET_RECOMMENDED_MUSIC = 'GET_RECOMMENDED_MUSIC';

// Clips
export const GET_CLIPS_FILTER = 'GET_CLIPS_FILTER';
export const GET_FILTERED_CLIPS = 'GET_FILTERED_CLIPS';
export const CLEAR_FILTERED_CLIPS = 'CLEAR_FILTERED_CLIPS';
export const GET_CLIP = 'GET_CLIP';
export const GET_CLIP_THREAD = 'GET_CLIP_THREAD';
export const CLEAR_CLIP_DATA = 'CLEAR_CLIP_DATA';
export const GET_NEW_CLIPS = 'GET_NEW_CLIPS';
export const GET_LAST_CLIPS = 'GET_LAST_CLIPS';
export const GET_TOP_CLIPS = 'GET_TOP_CLIPS';
export const GET_RECOMMENDED_CLIPS = 'GET_RECOMMENDED_CLIPS';

// Podcasts
export const GET_PODCASTS_FILTER = 'GET_PODCASTS_FILTER';
export const GET_FILTERED_PODCASTS = 'GET_FILTERED_PODCASTS';
export const CLEAR_FILTERED_PODCASTS = 'CLEAR_FILTERED_PODCASTS';
export const GET_PODCAST = 'GET_PODCAST';
export const GET_PODCAST_EPISODES = 'GET_PODCAST_EPISODES';
export const GET_PODCAST_THREAD = 'GET_PODCAST_THREAD';
export const CLEAR_PODCAST_DATA = 'CLEAR_PODCAST_DATA';
export const GET_NEW_PODCASTS = 'GET_NEW_PODCASTS';
export const GET_LAST_PODCASTS = 'GET_LAST_PODCASTS';
export const GET_TOP_PODCASTS = 'GET_TOP_PODCASTS';
export const GET_RECOMMENDED_PODCASTS = 'GET_RECOMMENDED_PODCASTS';

// Actor
export const GET_ACTOR_CONTENT = 'GET_ACTOR_CONTENT';

// Media and Album Details
export const GET_MEDIA_DETAILS = 'GET_MEDIA_DETAILS';
export const GET_ALBUM_DETAILS = 'GET_ALBUM_DETAILS';
export const CLEAR_DETAILS = 'CLEAR_DETAILS';

// Search
export const GET_SEARCH_CONTENT = 'GET_SEARCH_CONTENT';
export const RESET_SEARCH_CONTENT = 'RESET_SEARCH_CONTENT';

// Audios
export const GET_AUDIOS = 'GET_AUDIOS';
export const CLEAR_AUDIOS = 'CLEAR_AUDIOS';

// Sliders
export const GET_MAIN_SLIDES = 'GET_MAIN_SLIDES';
export const GET_TV_SLIDES = 'GET_TV_SLIDES';
export const GET_RADIO_SLIDES = 'GET_RADIO_SLIDES';
export const GET_MOVIES_SLIDES = 'GET_MOVIES_SLIDES';
export const GET_SERIALS_SLIDES = 'GET_SERIALS_SLIDES';
export const GET_CARTOONS_SLIDES = 'GET_CARTOONS_SLIDES';
export const GET_CLIPS_SLIDES = 'GET_CLIPS_SLIDES';
export const GET_MUSIC_SLIDES = 'GET_MUSIC_SLIDES';
export const GET_PODCASTS_SLIDES = 'GET_PODCASTS_SLIDES';
export const GET_AUDIOBOOKS_SLIDES = 'GET_AUDIOBOOKS_SLIDES';
