import initialState from './initialState';
import {
  GET_MEDIA_DETAILS,
  GET_ALBUM_DETAILS,
  CLEAR_DETAILS,
} from '../constants';

export default (state = initialState.contentDetails, action) => {
  switch (action.type) {
    case CLEAR_DETAILS:
      return {
        ...state,
        mediaDetails: {
          status: null,
          data: {},
        },
        albumDetails: {
          status: null,
          data: {},
        },
      };

    case GET_MEDIA_DETAILS:
      return {
        ...state,
        mediaDetails: action.response,
      };

    case GET_ALBUM_DETAILS:
      return {
        ...state,
        albumDetails: action.response,
      };

    default:
      return state;
  }
};
