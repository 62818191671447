import React from 'react';
import PropTypes from 'prop-types';

class Footer extends React.Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
  }

  render() {
    const { children } = this.props;

    return (
      <footer style={{ padding: '0 20px 20px' }}>
        {children}
      </footer>
    );
  }
}

export default Footer;
