import initialState from './initialState';
import {
  CHANGE_SIDEBAR_VISIBILITY,
  RECIVE_NOTIFICATION,
} from '../constants';

export default (state = initialState.common, action) => {
  switch (action.type) {
    case CHANGE_SIDEBAR_VISIBILITY:
      return {
        ...state,
        sidebarCollapsed: action.payload,
      };

    case RECIVE_NOTIFICATION:
      return {
        ...state,
        notifications: state.notifications.concat(action.response)
      };

    default:
      return state;
  }
};
