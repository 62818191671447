import initialState from './initialState';
import {
  GET_PODCASTS_FILTER,
  GET_FILTERED_PODCASTS,
  CLEAR_FILTERED_PODCASTS,
  GET_PODCAST,
  GET_PODCAST_EPISODES,
  GET_PODCAST_THREAD,
  CLEAR_PODCAST_DATA,
  GET_NEW_PODCASTS,
  GET_LAST_PODCASTS,
  GET_TOP_PODCASTS,
  GET_RECOMMENDED_PODCASTS,
} from '../constants';

export default (state = initialState.podcasts, action) => {
  switch (action.type) {
    case GET_PODCASTS_FILTER:
      return {
        ...state,
        availableFilters: action.response,
      };

    case GET_FILTERED_PODCASTS:
      return {
        ...state,
        filteredData: {
          status: action.response.status,
          pageLoaded: action.response.pageLoaded,
          hasNext: action.response.hasNext,
          data: state.filteredData.data.concat(action.response.data),
        },
      };

    case CLEAR_FILTERED_PODCASTS:
      return {
        ...state,
        filteredData: {
          status: null,
          pageLoaded: 1,
          hasNext: true,
          data: [],
        },
      };

    case GET_PODCAST:
      return {
        ...state,
        podcast: action.response,
      };

    case GET_PODCAST_EPISODES:
      return {
        ...state,
        episodes: action.response,
      };

    case GET_PODCAST_THREAD:
      return {
        ...state,
        podcastThread: action.response,
      };

    case CLEAR_PODCAST_DATA:
      return {
        ...state,
        podcast: {
          status: null,
          data: {},
        },
        podcastThreads: {
          status: null,
          data: [],
        },
      };

    case GET_NEW_PODCASTS:
      return {
        ...state,
        new: {
          status: action.response.status,
          pageLoaded: action.response.pageLoaded,
          hasNext: action.response.hasNext,
          data: state.new.data.concat(action.response.data),
        },
      };

    case GET_LAST_PODCASTS:
      return {
        ...state,
        last: {
          status: action.response.status,
          pageLoaded: action.response.pageLoaded,
          hasNext: action.response.hasNext,
          data: state.last.data.concat(action.response.data),
        },
      };

    case GET_TOP_PODCASTS:
      return {
        ...state,
        top: {
          status: action.response.status,
          pageLoaded: action.response.pageLoaded,
          hasNext: action.response.hasNext,
          data: state.top.data.concat(action.response.data),
        },
      };

    case GET_RECOMMENDED_PODCASTS:
      return {
        ...state,
        recommended: {
          status: action.response.status,
          pageLoaded: action.response.pageLoaded,
          hasNext: action.response.hasNext,
          data: state.recommended.data.concat(action.response.data),
        },
      };


    default:
      return state;
  }
};
