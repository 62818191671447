import styled from 'styled-components';
import playIcon from '../../assets/play-icon.svg';
import pauseIcon from '../../assets/pause-icon.svg';

export const MainContainer = styled.div`
  display: flex;
  align-items: flex-start;
  box-sizing: border-box;
  width: 350px;
  padding: 0 10px;
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const TimeContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  font-size: 12px;
  font-weight: 700;

  span.current-time,
  span.duration {
    width: 40px;
  }

  & input[type=range].amplitude-song-slider{
    -webkit-appearance: none;
    width: 280px;
    height: 3px;
    margin-bottom: 8px;
  }

  @media all and (-ms-high-contrast:none){
    *::-ms-backdrop, & input[type=range].amplitude-song-slider {
      margin-top: -8px;
    }
  }

  & input[type=range].amplitude-song-slider:focus {
    outline: none;
  }

  & input[type=range].amplitude-song-slider::-webkit-slider-runnable-track {
    width: 75%;
    height: 1px;
    cursor: pointer;
  }

  & input[type=range].amplitude-song-slider::-webkit-slider-thumb {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background: #fff;
    box-shadow: 0 0 50px rgba(255, 255, 255, .5);
    cursor: pointer;
    margin-top: -4px;
    -webkit-appearance: none;
  }

  & input[type=range].amplitude-song-slider::-moz-range-track {
    width: 100%;
    height: 1px;
    cursor: pointer;
  }

  & input[type=range].amplitude-song-slider::-moz-range-thumb {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background: #fff;
    box-shadow: 0 0 50px rgba(255, 255, 255, .5);
    cursor: pointer;
    margin-top: -4px;
  }

  & input[type=range].amplitude-song-slider::-ms-track {
    width: 100%;
    height: 1px;
    cursor: pointer;
  }

  & input[type=range].amplitude-song-slider::-ms-fill-lower {
    background: #CFD8DC;
    border-radius: 2.6px;
  }

  & input[type=range].amplitude-song-slider::-ms-fill-upper {
    background: #CFD8DC;
    border-radius: 2.6px;
  }

  & input[type=range].amplitude-song-slider::-ms-thumb {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background: #fff;
    box-shadow: 0 0 50px rgba(255, 255, 255, .5);
    cursor: pointer;
    margin-top: -4px;
  }

  & input[type=range].amplitude-song-slider:focus::-ms-fill-lower {
    background: #CFD8DC;
  }

  & input[type=range].amplitude-song-slider:focus::-ms-fill-upper {
    background: #CFD8DC;
  }

  & input[type=range].amplitude-song-slider::-ms-tooltip {
    display: none;
  }
`;

export const MetaContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const ControlsContainer = styled.div`
  display: flex;

  & div.amplitude-play-pause {
    width: 20px;
    height: 20px;
  }

  & div.amplitude-play-pause.amplitude-paused{
    background: url(${playIcon}) center no-repeat;
  }

  & div.amplitude-play-pause.amplitude-playing{
    background: url(${pauseIcon}) center no-repeat;
  }

`;
