import initialState from './initialState';
import {
  GET_AUDIOBOOKS_FILTER,
  GET_FILTERED_AUDIOBOOKS,
  CLEAR_FILTERED_AUDIOBOOKS,
  GET_AUDIOBOOK,
  GET_AUDIOBOOK_THREAD,
  CLEAR_AUDIOBOOK_DATA,
  GET_NEW_AUDIOBOOKS,
  GET_LAST_AUDIOBOOKS,
  GET_TOP_AUDIOBOOKS,
  GET_RECOMMENDED_AUDIOBOOKS,
} from '../constants';

export default (state = initialState.audiobooks, action) => {
  switch (action.type) {
    case GET_AUDIOBOOKS_FILTER:
      return { ...state, availableFilters: action.response };

    case GET_FILTERED_AUDIOBOOKS:
      return {
        ...state,
        filteredData: {
          status: action.response.status,
          pageLoaded: action.response.pageLoaded,
          hasNext: action.response.hasNext,
          data: state.filteredData.data.concat(action.response.data),
        },
      };

    case CLEAR_FILTERED_AUDIOBOOKS:
      return {
        ...state,
        filteredData: {
          status: null,
          pageLoaded: 1,
          hasNext: true,
          data: [],
        },
      };

    case GET_AUDIOBOOK:
      return {
        ...state,
        audiobook: action.response,
      };

    case GET_AUDIOBOOK_THREAD:
      return {
        ...state,
        audiobookThread: action.response,
      };

    case CLEAR_AUDIOBOOK_DATA:
      return {
        ...state,
        audiobook: {
          status: null,
          data: {},
        },
        audiobookThread: {
          status: null,
          data: {},
        },
      };

    case GET_NEW_AUDIOBOOKS:
      return  {
        ...state,
        new: {
          status: action.response.status,
          pageLoaded: action.response.pageLoaded,
          hasNext: action.response.hasNext,
          data: state.new.data.concat(action.response.data),
        },
      };

    case GET_LAST_AUDIOBOOKS:
      return {
        ...state,
        last: {
          status: action.response.status,
          pageLoaded: action.response.pageLoaded,
          hasNext: action.response.hasNext,
          data: state.last.data.concat(action.response.data),
        },
      };

    case GET_TOP_AUDIOBOOKS:
      return {
        ...state,
        top: {
          status: action.response.status,
          pageLoaded: action.response.pageLoaded,
          hasNext: action.response.hasNext,
          data: state.top.data.concat(action.response.data),
        },
      };

    case GET_RECOMMENDED_AUDIOBOOKS:
      return {
        ...state,
        recommended: {
          status: action.response.status,
          pageLoaded: action.response.pageLoaded,
          hasNext: action.response.hasNext,
          data: state.recommended.data.concat(action.response.data),
        },
      };

    default:
      return state;
  }
};
