/* eslint-disable react/destructuring-assignment */
import styled, { keyframes } from 'styled-components';
import theme from 'styled-theming';
import { Link } from 'react-router-dom';
import { rem } from 'polished';
import Icon from 'components/mediabay-ui/components/Icon';
import Button from 'components/mediabay-ui/components/Button';

export const StyledHeader = styled.header`
  z-index: 5;
  position: fixed;
  top: 0;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${theme('mode', { light: '#fff', dark: '#2c2e36' })};
  border-bottom: 1px solid ${theme('mode', { light: '#eee', dark: '#1d1d1d' })};
  color: ${theme('mode', { light: '#000', dark: '#fff' })};
  width: 100vw;
  height: 70px;
  padding: 15px 5px;
  box-shadow: 0 0 15px ${theme('mode', { light: '#eee', dark: '#111' })};

  @media (min-width: 1024px) {
    padding: ${rem('20px')} ${rem('15px')};
  }
`;

export const StyledIcon = styled(Icon)`
  margin: 0 5px;
  font-size: 24px;
`;

export const StyledContent = styled.main`
  position: relative;
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  background: ${theme('mode', { light: '#fff', dark: '#272727' })};
  color: ${theme('mode', { light: '#000', dark: '#fff' })};
  margin-top: 70px;

  @media (min-width: 1300px) {
    margin-left: ${props => (props.sidebarCollapsed ? 0 : '270px')};
  }
`;

export const StyledLayout = styled.div`
  flex-direction: column;
  box-sizing: border-box;
  background: ${theme('mode', { light: '#fff', dark: '#272727' })};
  height: calc(100vh - 75px);

  @media (min-width: 1024px) {
    height: calc(100vh - 75px);
  }

  @media (max-width: 1300px) {
    overflow-y: ${props => (props.sidebarCollapsed ? 'visible' : 'hidden')};
    height: calc(100vh - 75px);

    &:after {
      content: '';
      position: fixed;
      background: rgba(0, 0, 0, .4);
      display: ${props => (props.sidebarCollapsed ? 'none' : 'block')};
      left: 0;
      top: 0;
      right: 0;
      bottom:0;
    }
  }
`;

export const StyledSidebar = styled.aside`
  z-index: 4;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: ${props => (props.collapsed ? '0' : '270px')};
  overflow-x: hidden;
  overflow-y: auto;
  background: ${theme('mode', { light: '#f9f9f9', dark: '#1e1e1e' })};
  color: ${theme('mode', { light: '#000', dark: '#fff' })};

  &::-webkit-scrollbar {
    width: 5px;
  }
`;

export const StyledNav = styled.nav`
  padding: 100px 0 20px;
`;

export const StyledFooterNav = styled.div`
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  justify-content: center;
  list-style-type: none;
  margin: 15px 0;
  padding: 0;
`;

export const StyledFooterLink = styled(Link)`
  text-decoration: none;
  color: #737373;
  font-size: 16px;
  padding: 3px;
  transition: all .4s;

  &:hover {
    color: #fff;
  }
`;

export const FooterCopyright = styled.p`
  color: #737373;
  font-size: 14px;
  text-align: center;
`;

export const StyledFooterBtn = styled.button`
  background: transparent;
  border: 0;
  color: #737373;
  font-size: 14px;
  cursor: pointer;

  transition: all .4s;

  &:hover {
    color: #fff;
  }
`;

const pulseBest = keyframes`
  0% {
    box-shadow: 0 0 2px 0 rgba(0, 255, 6, 0.4);
  }
  70% {
    box-shadow: 0 0 2px 12px rgba(0, 255, 6, 0);
  }
  100% {
    box-shadow: 0 0 2px 0 rgba(0, 255, 6, 0);
  }
`;

const pulseGood = keyframes`
  0% {
    box-shadow: 0 0 2px 0 rgba(252, 247, 0, 0.4);
  }
  70% {
    box-shadow: 0 0 2px 12px rgba(252, 247, 0, 0);
  }
  100% {
    box-shadow: 0 0 2px 0 rgba(252, 247, 0, 0);
  }
`;

const pulseBad = keyframes`
  0% {
    box-shadow: 0 0 2px 0 rgba(243, 13, 13, 0.4);
  }
  70% {
    box-shadow: 0 0 2px 12px rgba(243, 13, 13, 0);
  }
  100% {
    box-shadow: 0 0 2px 0 rgba(243, 13, 13, 0);
  }
`;

const PulseDot = styled.span`
  display: inline-block;
  width: 8px;
  height: 8px;
  margin: 0 2.5px;
  border-radius: 50%;
`;

export const NetworkInfo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
`;

export const BestNetworkDot = styled(PulseDot)`
  background: #00ff06;
  animation: ${pulseBest} 1.5s linear infinite;
`;

export const GoodNetworkDot = styled(PulseDot)`
  background: yellow;
  animation: ${pulseGood} 1.5s linear infinite;
`;

export const BadNetworkDot = styled(PulseDot)`
  background: red;
  animation: ${pulseBad} 1.5s linear infinite;
`;

export const VirusButton = styled(Button)`
  display: none;

  @media(min-width: 500px) {
    display: block;
    margin-right: 20px;
    padding: 5px 10px;
  }
`;
