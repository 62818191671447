import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router'
import { i18nReducer } from 'redux-react-i18n';
import commonReducer from './commonReducer';
import userReducer from './userReducer';
import favoritesReducer from './favoritesReducer';
import slidersReducer from './slidersReducer';
import searchReducer from './searchReducer';
import tvReducer from './tvReducer';
import guideReducer from './guideReducer';
import radioReducer from './radioReducer';
import moviesReducer from './moviesReducer';
import serialsReducer from './serialsReducer';
import cartoonsReducer from './cartoonsReducer';
import clipsReducer from './clipsReducer';
import musicReducer from './musicReducer';
import podcastsReducer from './podcastsReducer';
import audiobooksReducer from './audiobooksReducer';
import actorReducer from './actorReducer';
import mediaDetailsReducer from './mediaDetailsReducer';
import articlesReducer from './articlesReducer';
import audiosReducer from './audiosReducer';

export default (history) => combineReducers({
  router: connectRouter(history),
  i18n: i18nReducer,
  common: commonReducer,
  user: userReducer,
  favorites: favoritesReducer,
  sliders: slidersReducer,
  search: searchReducer,
  tv: tvReducer,
  guide: guideReducer,
  radio: radioReducer,
  movies: moviesReducer,
  serials: serialsReducer,
  cartoons: cartoonsReducer,
  clips: clipsReducer,
  music: musicReducer,
  podcasts: podcastsReducer,
  actor: actorReducer,
  audiobooks: audiobooksReducer,
  contentDetails: mediaDetailsReducer,
  article: articlesReducer,
  audios: audiosReducer,
});
