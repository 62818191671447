import React from 'react';
import PropTypes from 'prop-types';
import { StyledContent } from './styles';

class Content extends React.Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    sidebarCollapsed: PropTypes.bool.isRequired,
  }

  render() {
    const { sidebarCollapsed, children } = this.props;

    return (
      <StyledContent sidebarCollapsed={sidebarCollapsed}>
        {children}
      </StyledContent>
    );
  }
}

export default Content;
