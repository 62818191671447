import initialState from './initialState';
import {
  GET_MUSIC_FILTER,
  GET_FILTERED_MUSIC,
  CLEAR_FILTERED_MUSIC,
  GET_ALBUM,
  GET_MUSIC_THREAD,
  CLEAR_MUSIC_DATA,
  GET_NEW_MUSIC,
  GET_LAST_MUSIC,
  GET_TOP_MUSIC,
  GET_RECOMMENDED_MUSIC,
} from '../constants';

export default (state = initialState.music, action) => {
  switch (action.type) {
    case GET_MUSIC_FILTER:
      return {
        ...state,
        availableFilters: action.response,
      };

    case GET_FILTERED_MUSIC:
      return {
        ...state,
        filteredData: {
          status: action.response.status,
          pageLoaded: action.response.pageLoaded,
          hasNext: action.response.hasNext,
          data: state.filteredData.data.concat(action.response.data),
        },
      };

    case CLEAR_FILTERED_MUSIC:
      return {
        ...state,
        filteredData: {
          status: null,
          pageLoaded: 1,
          hasNext: true,
          data: [],
        },
      };

    case GET_ALBUM:
      return {
        ...state,
        album: action.response,
      };

    case GET_MUSIC_THREAD:
      return {
        ...state,
        musicThread: action.response,
      };

    case CLEAR_MUSIC_DATA:
      return {
        ...state,
        album: {
          status: null,
          data: {},
        },
        musicThread: {
          status: null,
          data: {},
        },
      };

    case GET_NEW_MUSIC:
      return {
        ...state,
        new: {
          status: action.response.status,
          pageLoaded: action.response.pageLoaded,
          hasNext: action.response.hasNext,
          data: state.new.data.concat(action.response.data),
        },
      };

    case GET_LAST_MUSIC:
      return {
        ...state,
        last: {
          status: action.response.status,
          pageLoaded: action.response.pageLoaded,
          hasNext: action.response.hasNext,
          data: state.last.data.concat(action.response.data),
        },
      };

    case GET_TOP_MUSIC:
      return {
        ...state,
        top: {
          status: action.response.status,
          pageLoaded: action.response.pageLoaded,
          hasNext: action.response.hasNext,
          data: state.top.data.concat(action.response.data),
        },
      };

    case GET_RECOMMENDED_MUSIC:
      return {
        ...state,
        recommended: {
          status: action.response.status,
          pageLoaded: action.response.pageLoaded,
          hasNext: action.response.hasNext,
          data: state.recommended.data.concat(action.response.data),
        },
      };


    default:
      return state;
  }
};
