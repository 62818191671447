import styled from 'styled-components';
import playIcon from '../../assets/play-icon.svg';
import pauseIcon from '../../assets/pause-icon.svg';

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: space-around;
  position: relative;
  box-sizing: border-box;
  width: 350px;
  padding: 0 10px;
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const MetaContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const ControlsContainer = styled.div`
  display: flex;

  & div.amplitude-play-pause {
    width: 20px;
    height: 20px;
  }

  & div.amplitude-play-pause.amplitude-paused{
    background: url(${playIcon}) center no-repeat;
  }

  & div.amplitude-play-pause.amplitude-playing{
    background: url(${pauseIcon}) center no-repeat;
  }

`;
