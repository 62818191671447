import React from 'react';
import PropTypes from 'prop-types';
import { StyledLayout } from './styles';

class Layout extends React.Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    sidebarCollapsed: PropTypes.bool,
  }

  static defaultProps = {
    sidebarCollapsed: null,
  }

  render() {
    const { sidebarCollapsed, children } = this.props;

    return (
      <StyledLayout
        sidebarCollapsed={sidebarCollapsed}
        {...this.props}
      >
        {children}
      </StyledLayout>
    );
  }
}

export default Layout;
