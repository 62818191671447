import React, { useEffect } from 'react';
/* eslint-disable */
const YandexAnalytics = React.memo(() => {
  useEffect(() => {
    (function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};
      m[i].l=1*new Date();k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})
      (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");

      ym(process.env.REACT_APP_YA_ID || null, "init", {
        clickmap:false,
        trackLinks:true,
        accurateTrackBounce:true
      });
  }, [])

  return (
    <div />
  );
});

export default YandexAnalytics;
