export default {
  // Page titles
  homeTitle: 'Mediabay - Home Page',

  // Common
  oldSite: 'Old version of the site',
  pageError: 'Something went wrong...',
  pageNotFound: 'Page not found',
  returnToHome: 'Home Page',
  loadingError: 'Loading error. Please try to reload the page',
  tvPlayer: 'TV Player',
  watch: 'Watch',
  watchAll: 'Watch more',
  buy: 'Buy',
  active: 'Active',
  copyrightUz: 'OOO "Mediabay Asia"',
  copyrightTv: '"International Mediabay Alliance LLC"',
  policy: 'Privacy policy',
  yourIp: 'Your IP:',
  yourCountry: 'Your country:',
  connection: 'Connection',
  noSearchData: 'Sorry, nothing found for your request',
  watchEverywhere: 'Watch Mediabay everywhere',
  watchDevices: 'Smart TV, iPad or iPhone, Android smartphones or tablets, PCs or Laptops',
  allChannels: 'All channels',
  details: 'Details',
  episodes: 'Episodes',
  filterLabel: 'Filter',
  filterReset: 'Clear',
  moviesGenres: 'Movies\' genres',
  serialsGenres: 'Serials\' genres',
  podcastsGenres: 'Podcasts\' genres',
  clipsGenres: 'Clips\' genres',
  musicGenres: 'Music\'s genres',
  cartoonsGenres: 'Cartoons\' genres',
  audiobooksGenres: 'Audiobooks\' genres',
  country: 'Country',
  releaseYear: 'Year',
  language: 'Language',

  // Not available data
  noData: 'No available data',
  noNotificationsData: 'You have not notifications',
  noChannelsData: 'Error loading channels',
  noMediaData: 'Error loading data',
  noGuideData: 'No available guide for current TV channel',
  noTransactions: 'You have not history of transactions',


  // Sections
  home: 'Home',
  tv: 'TV Online',
  radio: 'Radio',
  newMovies: 'New Movies',
  movies: 'Movies',
  serials: 'Serials',
  cartoons: 'Cartoons',
  podcasts: 'TV Shows',
  clips: 'Clips',
  music: 'Music',
  audioBooks: 'Audiobooks',
  favorites: 'Favorites',
  apps: 'Download Apps',

  // Guide
  tvGuide: 'TV Guide',
  monday: 'Mon',
  tuesday: 'Tue',
  wednesday: 'Wed',
  thursday: 'Thu',
  friday: 'Fri',
  saturday: 'Sat',
  sunday: 'Sun',
  live: 'Live',
  nextItem: 'Next',
  evenLater: 'Even later',

  // Content genres
  freeTv: 'Free TV Channels',
  premiumTv: 'Premium TV Channels',
  freeRadio: 'Radio Channels',
  new: 'New releases',
  last: 'Recently added',
  top: 'Top',
  recommended: 'Recommended',

  // Auth
  authEnter: 'Войти',
  authReg: 'Зарегистрироваться',
  signIn: 'Sign In',
  signUp: 'Sign Up',
  authAction: 'Sign In or Sign Up',
  rulesWarning: 'Нажимая кнопку "Зарегистироваться" вы принимаете ',
  name: 'Your name',
  email: 'Your email',
  emailOrLogin: 'Email or Login',
  login: 'Your username',
  password: 'Password',
  confirmPassword: 'Confirm password',
  needEmailConfirm: 'Registration was successful, confirmation sent to your email',
  successfullyConfirm: 'Email confirmation success',
  successfullyValidated: 'Password confirmation success',
  errorConfirm: 'Email confirmation error',
  errorValidate: 'New password confirmation error',
  continue: 'Next',
  save: 'Save',
  forgotPassword: 'Forgot password?',
  resendConfirmEmail: 'Отправить письмо подтверждения повторно',

  // User
  logIn: 'Log In',
  logOut: 'Log out',
  id: 'Your ID:',
  balance: 'Your Balance:',
  expired: 'Voucher expired:',
  notActiveVoucher: 'not active',
  profileData: 'Your Profile',
  settings: 'Settings',
  subscribeChannelInfo: 'It\'s a premium channel, please buy voucher for watch this',
  subscribeAction: 'Buy Voucher',
  profileFavorites: 'Favorites',
  profileNoFavoritesData: 'You have not favorite content',
  profilePay: 'Balance replenishment',
  profilePaymentLabel: 'Payment price',
  profilePaymentService: 'Choose payment service',
  profileServices: 'Our services',
  ProfilePayAndBuy: 'Payment and services',
  ProfilePayAndBuyForTv: 'Payment',
  profileTransactions: 'Your transactions',
  profileTransactionsDate: 'Date',
  profileTransactionsDescr: 'Description',
  profileTransactionsAmount: 'Amount',
  profileSettings: 'Settings',
  profileSettingsUpdateSuccess: 'Successfully changed',

  // Footer
  about: 'About',
  licenses: 'Licenses',
  rules: 'Rules',
  contacts: 'Contacts',
};
