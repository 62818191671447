import initialState from './initialState';
import { GET_AUDIOS, CLEAR_AUDIOS } from '../constants';

export default (state = initialState.audios, action) => {
  switch (action.type) {
    case CLEAR_AUDIOS:
      return {
        ...state,
        status: null,
        data: [],
      };

    case GET_AUDIOS:
      return {
        ...state,
        ...action.response,
      }

    default:
      return state;
  }
};
