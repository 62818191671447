import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Icon from 'components/mediabay-ui/components/Icon';

const Form = styled.form`
  position: relative;
`;

const Input = styled.input`
  font-family: 'Museo Sans Regular', sans-serif;
  background: #1e1e1e;
  border: 0;
  padding: 10px 15px;
  border-radius: 24px;
  color: #fff;
  outline: 0;
`;

const Button = styled.button`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 10px;
  background: transparent;
  border: 0;
  outline: 0;
`;

class SearchForm extends React.Component {
  static propTypes = {
    // Actions
    routerActions: PropTypes.shape({
      push: PropTypes.func,
    }).isRequired,
    getSearchData: PropTypes.func.isRequired,
    resetSearchData: PropTypes.func.isRequired,

    // Handlers
    closeSearchHandler: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      searchQuery: '',
    };

    this.onChangeHandler = this.onChangeHandler.bind(this);
    this.onBlur = this.onBlur.bind(this);
  }

  componentDidUpdate() {
    if (this.state.searchQuery.length > 3) {
      this.props.resetSearchData();
      this.props.getSearchData(this.state.searchQuery);
    }
  }

  onChangeHandler(e) {
    // Fired when length > 2
    // Because it doesn't know after new state until 3rd keypress
    if (this.state.searchQuery.length > 1) {
      this.props.routerActions.push('/search');
    }

    this.setState({
      searchQuery: e.target.value,
    });
  }

  onBlur() {
    this.props.closeSearchHandler();
    this.setState({
      searchQuery: '',
    });
  }

  render() {
    return (
      <Form>
        <Input
          autoFocus
          type="text"
          value={this.state.searchQuery}
          onChange={this.onChangeHandler}
          onBlur={this.onBlur}
        />

        <Button onClick={this.props.closeSearchHandler}>
          <Icon icon="close" width={14} height={14} />
        </Button>
      </Form>
    );
  }
}

export default SearchForm;
