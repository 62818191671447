import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import theme from 'styled-theming';
import { rem } from 'polished';
import Icon from 'components/mediabay-ui/components/Icon';

const Item = ({ className, to, children }) => (
  // eslint-disable-next-line jsx-a11y/anchor-is-valid
  <Link className={className} to={to}>
    {children}
  </Link>
);

const LinkStyled = styled(Item)`
  display: flex;
  align-items: center;
  padding: 10px 20px;
  font-size: ${rem('16px')};
  text-decoration: none;
  color: ${theme('mode', { light: '#000', dark: '#fff' })};
  cursor: pointer;

  &:hover {
    background: ${theme('mode', { light: '#f1f1f1', dark: '#111' })};
  }
`;

class MenuLink extends React.Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    icon: PropTypes.string,
  }

  static defaultProps = {
    icon: null,
  }

  render() {
    const { icon } = this.props;
    const iconNode = this.props.icon ? (
      <Icon
        icon={icon}
        style={{ margin: '0 10px 0 5px', width: 30 }}
      />
    ) : null;

    return (
      <LinkStyled {...this.props}>
        {iconNode}
        {this.props.children}
      </LinkStyled>
    );
  }
}

Item.propTypes = {
  className: PropTypes.string.isRequired,
  to: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      pathname: PropTypes.string,
      search: PropTypes.string,
      hash: PropTypes.string,
    }),
  ]),
  children: PropTypes.node.isRequired,
};

Item.defaultProps = {
  to: null,
};

export default MenuLink;
