import initialState from './initialState';
import {
  GET_CHANNEL_GUIDE,
  GET_WEEKDAY_CHANNEL_GUIDE,
  GET_CHANNELS_GUIDE,
  // GET_CHANNELS_GUIDE,
} from '../constants';

export default (state = initialState.guide, action) => {
  switch (action.type) {
    case GET_CHANNEL_GUIDE:
      return {
        ...state,
        channelGuide: action.response,
      };

    case GET_CHANNELS_GUIDE:
      return {
        ...state,
        channelsGuide: action.response,
      };

    case GET_WEEKDAY_CHANNEL_GUIDE:
      return {
        ...state,
        weekdayChannelGuide: action.response,
      };

    default:
      return state;
  }
};
