import axios from 'axios';
import qs from 'qs';
import {
  GET_INITIAL_USER_INFO,
  CHECK_EMAIL,
  CONFIRM_USER_EMAIL,
  USER_SIGN_UP,
  USER_SIGN_IN,
  USER_SIGN_OUT,
  GET_USER_TRANSACTIONS,
  GET_AVAILABLE_VOUCHERS,
  UPDATE_USER_DATA,
} from '../constants';

export const getInitialUserInfoSuccess = response => ({
  type: GET_INITIAL_USER_INFO,
  response,
});

export const getInitialUserInfo = () => dispatch => (
  axios.get('/users/login')
    .then((response) => {
      const data = {
        status: response.status,
        data: response.data,
      };
      return dispatch(getInitialUserInfoSuccess(data));
    })
    .catch((error) => {
      const data = {
        status: null,
        data: {},
      };
      if (error.response) {
        data.status = error.response.status;
      } else if (error.request) {
        data.status = 408;
      } else {
        data.status = 0;
      }
      dispatch(getInitialUserInfoSuccess(data));
    })
);

export const userSignInSuccess = response => ({
  type: USER_SIGN_IN,
  response,
});

export const userSignIn = values => (dispatch) => {
  axios({
    method: 'post',
    url: '/users/login',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' },
    data: qs.stringify(values),
  })
    .then((response) => {
      const data = {
        status: response.status,
        data: response.data,
      };
      return dispatch(userSignInSuccess(data));
    })
    .catch((error) => {
      const data = {
        status: null,
        data: {},
      };
      if (error.response) {
        data.status = error.response.status;
      } else if (error.request) {
        data.status = 408;
      } else {
        data.status = 0;
      }
      dispatch(userSignInSuccess(data));
    });
};

export const userSocialSignIn = (service, token) => (dispatch) => {
  axios.get(`/users/${service}/login?access_token=${token}`)
    .then((response) => {
      const data = {
        status: response.status,
        data: response.data,
      };
      return dispatch(userSignInSuccess(data));
    })
    .catch((error) => {
      const data = {
        status: null,
        data: {},
      };
      if (error.response) {
        data.status = error.response.status;
      } else if (error.request) {
        data.status = 408;
      } else {
        data.status = 0;
      }
      dispatch(userSignInSuccess(data));
    });
};

export const userSignUpSuccess = response => ({
  type: USER_SIGN_UP,
  response,
});

export const userSignUp = (email, password, confirmPassword) => (dispatch) => {
  axios({
    method: 'post',
    url: '/users/register',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' },
    data: qs.stringify({
      email,
      password,
      confirmPassword,
      approved: true,
    }),
  })
    .then((response) => {
      const data = {
        status: response.status,
        data: response.data,
      };
      return dispatch(userSignUpSuccess(data));
    })
    .catch((error) => {
      const data = {
        status: null,
        data: {},
      };
      if (error.response) {
        data.status = error.response.status;
      } else if (error.request) {
        data.status = 0;
      } else {
        data.status = 0;
      }
      dispatch(userSignUpSuccess(data));
    });
};

export const userSignOutSuccess = response => ({
  type: USER_SIGN_OUT,
  response,
});

export const userSignOut = () => (dispatch) => {
  axios.get('/users/logout')
    .then((response) => {
      const data = {
        status: response.status,
        data: response.data,
      };
      return dispatch(userSignOutSuccess(data));
    })
    .catch((error) => {
      const data = {
        status: null,
        data: {},
      };
      if (error.response) {
        data.status = error.response.status;
      } else if (error.request) {
        data.status = 0;
      } else {
        data.status = 0;
      }
      dispatch(userSignOutSuccess(data));
    });
};

export const checkEmailSuccess = response => ({
  type: CHECK_EMAIL,
  response,
});

export const checkEmail = email => (dispatch) => {
  axios({
    method: 'post',
    url: '/users/emailcheck',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' },
    data: qs.stringify({ email }),
  })
    .then((response) => {
      const data = {
        status: response.status,
        data: response.data.data,
      };
      return dispatch(checkEmailSuccess(data));
    })
    .catch((error) => {
      const data = {
        status: null,
        data: {},
      };
      if (error.response) {
        data.status = error.response.status;
      } else if (error.request) {
        data.status = 0;
      } else {
        data.status = 0;
      }
      dispatch(checkEmailSuccess(data));
    });
};

export const getUserTransactionsSuccess = response => ({
  type: GET_USER_TRANSACTIONS,
  response,
});

export const getUserTransactions = () => (dispatch) => {
  axios.get('/users/operations')
    .then((response) => {
      const data = {
        status: response.status,
        data: response.data.data.reverse(),
      };
      return dispatch(getUserTransactionsSuccess(data));
    })
    .catch((error) => {
      const data = {
        status: null,
        data: {},
      };
      if (error.response) {
        data.status = error.response.status;
      } else if (error.request) {
        data.status = 408;
      } else {
        data.status = 0;
      }
      dispatch(getUserTransactionsSuccess(data));
    });
};

export const getAvailableVouchersSuccess = response => ({
  type: GET_AVAILABLE_VOUCHERS,
  response,
});

export const getAvailableVouchers = () => (dispatch) => {
  axios.get('/transfers/vouchers')
    .then((response) => {
      const data = {
        status: response.status,
        data: response.data.data,
      };
      return dispatch(getAvailableVouchersSuccess(data));
    })
    .catch((error) => {
      const data = {
        status: null,
        data: {},
      };
      if (error.response) {
        data.status = error.response.status;
      } else if (error.request) {
        data.status = 0;
      } else {
        data.status = 0;
      }
      dispatch(getAvailableVouchersSuccess(data));
    });
};

export const updateUserDataSuccess = response => ({
  type: UPDATE_USER_DATA,
  response,
});

export const updateUserData = (name, password, confirmPassword) => (dispatch) => {
  axios({
    method: 'post',
    url: '/users/update',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' },
    data: qs.stringify({ name, password, confirmPassword }),
  })
    .then((response) => {
      const data = {
        status: response.status,
        data: response.data,
      };
      return dispatch(updateUserDataSuccess(data));
    })
    .catch((error) => {
      const data = {
        status: null,
        data: {},
      };
      if (error.response) {
        data.status = error.response.status;
      } else if (error.request) {
        data.status = 0;
      } else {
        data.status = 0;
      }
      dispatch(updateUserDataSuccess(data));
    });
};


export const confirmEmailSuccess = response => ({
  type: CONFIRM_USER_EMAIL,
  response,
});

export const confirmEmail = hash => (dispatch) => {
  axios.get(`/users/confirm/${hash}`)
    .then((response) => {
      const data = {
        status: response.status,
        data: response.data,
      };
      return dispatch(confirmEmailSuccess(data));
    })
    .catch((error) => {
      const data = {
        status: null,
        data: {},
      };
      if (error.response) {
        data.status = error.response.status;
      } else if (error.request) {
        data.status = 0;
      } else {
        data.status = 0;
      }
      dispatch(confirmEmailSuccess(data));
    });
};

export const confirmPasswordSuccess = response => ({
  type: CONFIRM_USER_EMAIL,
  response,
});

export const confirmPassword = hash => (dispatch) => {
  axios.get(`/users/validate/${hash}`)
    .then((response) => {
      const data = {
        status: response.status,
        data: response.data,
      };
      return dispatch(confirmPasswordSuccess(data));
    })
    .catch((error) => {
      const data = {
        status: null,
        data: {},
      };
      if (error.response) {
        data.status = error.response.status;
      } else if (error.request) {
        data.status = 0;
      } else {
        data.status = 0;
      }
      dispatch(confirmPasswordSuccess(data));
    });
};
