import initialState from './initialState';
import {
  GET_CARTOONS_FILTER,
  GET_FILTERED_CARTOONS,
  CLEAR_FILTERED_CARTOONS,
  GET_CARTOON,
  GET_CARTOON_EPISODES,
  GET_CARTOON_THREAD,
  CLEAR_CARTOON_DATA,
  GET_NEW_CARTOONS,
  GET_LAST_CARTOONS,
  GET_TOP_CARTOONS,
  GET_RECOMMENDED_CARTOONS,
} from '../constants';

export default (state = initialState.cartoons, action) => {
  switch (action.type) {
    case GET_CARTOONS_FILTER:
      return {
        ...state,
        availableFilters: action.response,
      };

    case GET_FILTERED_CARTOONS:
      return {
        ...state,
        filteredData: {
          status: action.response.status,
          pageLoaded: action.response.pageLoaded,
          hasNext: action.response.hasNext,
          data: state.filteredData.data.concat(action.response.data),
        },
      };

    case CLEAR_FILTERED_CARTOONS:
      return {
        ...state,
        filteredData: {
          status: null,
          pageLoaded: 1,
          hasNext: true,
          data: [],
        },
      };

    case GET_CARTOON:
      return {
        ...state,
        cartoon: action.response,
      };

    case GET_CARTOON_EPISODES:
      return {
        ...state,
        episodes: action.response,
      };

    case GET_CARTOON_THREAD:
      return {
        ...state,
        cartoonThread: action.response,
      };

    case CLEAR_CARTOON_DATA:
      return {
        ...state,
        cartoon: {
          status: null,
          data: {},
        },
        cartoonThreads: {
          status: null,
          data: [],
        },
      };

    case GET_NEW_CARTOONS:
      return {
        ...state,
        new: {
          status: action.response.status,
          pageLoaded: action.response.pageLoaded,
          hasNext: action.response.hasNext,
          data: state.new.data.concat(action.response.data),
        },
      };

    case GET_LAST_CARTOONS:
      return {
        ...state,
        last: {
          status: action.response.status,
          pageLoaded: action.response.pageLoaded,
          hasNext: action.response.hasNext,
          data: state.last.data.concat(action.response.data),
        },
      };

    case GET_TOP_CARTOONS:
      return {
        ...state,
        top: {
          status: action.response.status,
          pageLoaded: action.response.pageLoaded,
          hasNext: action.response.hasNext,
          data: state.top.data.concat(action.response.data),
        },
      };

    case GET_RECOMMENDED_CARTOONS:
      return {
        ...state,
        recommended: {
          status: action.response.status,
          pageLoaded: action.response.pageLoaded,
          hasNext: action.response.hasNext,
          data: state.recommended.data.concat(action.response.data),
        },
      };

    default:
      return state;
  }
};
