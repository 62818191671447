import axios from 'axios';
import {
  GET_RADIO_CHANNELS,
  SET_RADIO_PLAYER,
  CHANGE_RADIO_CHANNEL,
  CLEAR_RADIO_PLAYER,
} from '../constants';

export const clearRadioPlayer = () => ({
  type: CLEAR_RADIO_PLAYER,
});

export const updateSelectedIndex = response => ({
  type: CHANGE_RADIO_CHANNEL,
  response,
});

export const setRadioPlayer = response => ({
  type: SET_RADIO_PLAYER,
  response,
});

export const getRadioSuccess = response => ({
  type: GET_RADIO_CHANNELS,
  response,
});

export const getRadio = () => dispatch => (
  axios.get('/channels/listradio')
    .then((response) => {
      const data = {
        status: response.status,
        mediaHost: response.data.mediaHost,
        data: response.data.data,
      };
      return dispatch(getRadioSuccess(data));
    })
    .catch((error) => {
      const data = {
        status: 0,
        data: [],
      };
      if (error.response) {
        data.status = error.response.status;
      } else if (error.request) {
        data.status = 0;
      } else {
        data.status = 0;
      }
      dispatch(getRadioSuccess(data));
    })
);
