import React from 'react';
import PropTypes from 'prop-types';
import Dotdotdot from 'react-dotdotdot';
import Amplitude from 'amplitudejs';
import Icon from 'components/mediabay-ui/components/Icon';
import {
  MainContainer,
  TimeContainer,
  Row,
  MetaContainer,
  ControlsContainer,
} from './styles';

class AudioPlayer extends React.Component {
  static propTypes = {
    clearAudios: PropTypes.func.isRequired,
    audios: PropTypes.shape({
      status: PropTypes.number,
      data: PropTypes.arrayOf(PropTypes.object),
    }).isRequired,
  };

  constructor() {
    super();

    this.closeBtnHandler = this.closeBtnHandler.bind(this);
  }

  componentDidMount() {
    const { audios } = this.props;
    Amplitude.init({
      songs: audios.data,
    });
  }

  componentWillUnmount() {
    Amplitude.pause();
  }

  closeBtnHandler() {
    const { clearAudios } = this.props;
    clearAudios();
  }

  render() {
    return (
      <MainContainer>
        <div>
          <Row>
            <TimeContainer id="time-container">
              <input
                type="range"
                className="amplitude-song-slider"
                amplitude-main-song-slider="true"
                step=".1"
                style={{ width: '100%' }}
              />
            </TimeContainer>
          </Row>

          <Row style={{ padding: '0 5px' }}>
            <MetaContainer id="meta-container">
              <img amplitude-song-info="cover_art_url" amplitude-main-song-info="true" alt="" style={{ marginRight: 10 }} />
              <Dotdotdot clamp={2} useNativeClamp>
                <span amplitude-song-info="name" amplitude-main-song-info="true" className="song-name" />
              </Dotdotdot>
            </MetaContainer>

            <ControlsContainer id="control-container" style={{ marginLeft: 10 }}>
              <div className="amplitude-prev">
                <Icon icon="prev" width={16} height={16} />
              </div>
              <div
                className="amplitude-play-pause"
                amplitude-main-play-pause="true"
                style={{ margin: '0 10px' }}
              />
              <div className="amplitude-next" id="next">
                <Icon icon="next" width={16} height={16} />
              </div>
            </ControlsContainer>
          </Row>
        </div>
        <div>
          <Icon
            icon="close"
            width={14}
            height={14}
            style={{ margin: '-5px 0 0 20px', cursor: 'pointer' }}
            onClick={this.closeBtnHandler}
          />
        </div>
      </MainContainer>
    );
  }
}

export default AudioPlayer;
