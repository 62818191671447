import axios from 'axios';
import { GET_AUDIOS, CLEAR_AUDIOS } from '../constants';

export const clearAudios = () => ({
  type: CLEAR_AUDIOS,
});

export const getAudiosSuccess = response => ({
  type: GET_AUDIOS,
  response,
});

export const getAlbumThreads = (id, lang) => dispatch => (
  axios.get(`/music/playlist/parts/${id}?size=100`)
    .then((response) => {
      const data = {
        status: response.status,
        data: response.data.data.map(item => (
          {
            id: item.id,
            name: item.title[lang],
            url: item.thread,
            cover_art_url: 'https://devmedia.mediabay.uz/media/covers/music/musicnocover.40.jpg',
          }
        )),
      };
      return dispatch(getAudiosSuccess(data));
    })
    .catch((error) => {
      const data = {
        status: 0,
        data: [],
      };
      if (error.response) {
        data.status = error.response.status;
      } else if (error.request) {
        data.status = 0;
      } else {
        data.status = 0;
      }
      dispatch(getAudiosSuccess(data));
    })
);

export const getAudiobookThreads = (id, lang) => dispatch => (
  axios.get(`/music/audiobook/parts/${id}?size=100`)
    .then((response) => {
      const data = {
        status: response.status,
        data: response.data.data.map(item => (
          {
            id: item.id,
            name: item.title[lang],
            url: item.thread,
            cover_art_url: 'https://devmedia.mediabay.uz/media/covers/music/musicnocover.40.jpg',
          }
        )),
      };
      return dispatch(getAudiosSuccess(data));
    })
    .catch((error) => {
      const data = {
        status: 0,
        data: [],
      };
      if (error.response) {
        data.status = error.response.status;
      } else if (error.request) {
        data.status = 0;
      } else {
        data.status = 0;
      }
      dispatch(getAudiosSuccess(data));
    })
);
