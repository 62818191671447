import initialState from './initialState';
import {
  GET_SERIALS_FILTER,
  GET_FILTERED_SERIALS,
  CLEAR_FILTERED_SERIALS,
  GET_SERIAL,
  GET_SERIAL_EPISODES,
  GET_SERIAL_THREAD,
  CLEAR_SERIAL_DATA,
  GET_NEW_SERIALS,
  GET_LAST_SERIALS,
  GET_TOP_SERIALS,
  GET_RECOMMENDED_SERIALS,
} from '../constants';

export default (state = initialState.serials, action) => {
  switch (action.type) {
    case GET_SERIALS_FILTER:
      return {
        ...state,
        availableFilters: action.response,
      };

    case GET_FILTERED_SERIALS:
      return {
        ...state,
        filteredData: {
          status: action.response.status,
          pageLoaded: action.response.pageLoaded,
          hasNext: action.response.hasNext,
          data: state.filteredData.data.concat(action.response.data),
        },
      };

    case CLEAR_FILTERED_SERIALS:
      return {
        ...state,
        filteredData: {
          status: null,
          pageLoaded: 1,
          hasNext: true,
          data: [],
        },
      };

    case GET_SERIAL:
      return {
        ...state,
        serial: action.response,
      };

    case GET_SERIAL_EPISODES:
      return {
        ...state,
        episodes: action.response,
      };

    case GET_SERIAL_THREAD:
      return {
        ...state,
        serialThread: action.response,
      };

    case CLEAR_SERIAL_DATA:
      return {
        ...state,
        serial: {
          status: null,
          data: {},
        },
        serialThreads: {
          status: null,
          data: [],
        },
      };

    case GET_NEW_SERIALS:
      return {
        ...state,
        new: {
          status: action.response.status,
          pageLoaded: action.response.pageLoaded,
          hasNext: action.response.hasNext,
          data: state.new.data.concat(action.response.data),
        },
      };

    case GET_LAST_SERIALS:
      return {
        ...state,
        last: {
          status: action.response.status,
          pageLoaded: action.response.pageLoaded,
          hasNext: action.response.hasNext,
          data: state.last.data.concat(action.response.data),
        },
      };

    case GET_TOP_SERIALS:
      return {
        ...state,
        top: {
          status: action.response.status,
          pageLoaded: action.response.pageLoaded,
          hasNext: action.response.hasNext,
          data: state.top.data.concat(action.response.data),
        },
      };

    case GET_RECOMMENDED_SERIALS:
      return {
        ...state,
        recommended: {
          status: action.response.status,
          pageLoaded: action.response.pageLoaded,
          hasNext: action.response.hasNext,
          data: state.recommended.data.concat(action.response.data),
        },
      };


    default:
      return state;
  }
};
