import initialState from './initialState';
import { GET_SEARCH_CONTENT, RESET_SEARCH_CONTENT } from '../constants';

export default (state = initialState.search, action) => {
  switch (action.type) {
    case GET_SEARCH_CONTENT:
      return {
        ...state,
        ...action.response,
      };

    case RESET_SEARCH_CONTENT:
      return {
        ...state,
        status: null,
        data: {
          channels: [],
          movies: [],
          serials: [],
          cartoons: [],
          clips: [],
          music: [],
          podcasts: [],
          audiobooks: [],
          actors: [],
          producer: [],
        },
      };

    default:
      return state;
  }
};
