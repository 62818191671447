import React from 'react';
import PropTypes from 'prop-types';
import { Loc } from 'redux-react-i18n';
import Button from 'components/mediabay-ui/components/Button';
import styled from 'styled-components';
// import trackError from '../utils/trackError';
import bgImage from '../assets/content-blur-bg.jpg';
import logo from '../assets/mb-logo-full.svg';

const Container = styled.div`
  text-align: center;
  height: 100vh;
  padding: 100px 0;
  background: url(${bgImage}), rgba(0, 0, 0, 0.8);
`;

class ErrorBoundary extends React.Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
  }

  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch() {
    // Display fallback UI
    this.setState({ hasError: true });

    // Log error to server
    // trackError(error);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <Container>
          <a href="/">
            <img src={logo} alt="Mediabay logo" style={{ width: 250, height: 'auto' }} />
          </a>
          <h3 style={{ textAlign: 'center', fontSize: 30, color: '#fff' }}>
            <Loc locKey="pageError" />
          </h3>
          <a href="/">
            <Button type="primary" style={{ margin: 'auto' }}>
              <Loc locKey="returnToHome" />
            </Button>
          </a>
        </Container>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
