import initialState from './initialState';
import { GET_ACTOR_CONTENT } from '../constants';

export default (state = initialState.actor, action) => {
  switch (action.type) {
    case GET_ACTOR_CONTENT:
      return {
        ...state,
        ...action.response,
      }

    default:
      return state;
  }
};
