import initialState from './initialState';
import {
  GET_RADIO_CHANNELS,
  SET_RADIO_PLAYER,
  CHANGE_RADIO_CHANNEL,
  CLEAR_RADIO_PLAYER,
} from '../constants';

export default (state = initialState.radio, action) => {
  switch (action.type) {
    case GET_RADIO_CHANNELS:
      return {
        ...state,
        radioChannels: action.response,
      };

    case SET_RADIO_PLAYER:
      return {
        ...state,
        player: action.response,
      };

    case CHANGE_RADIO_CHANNEL:
      return {
        ...state,
        player: {
          ...state.player,
          selectedIndex: action.response,
        },
      };

    case CLEAR_RADIO_PLAYER:
      return {
        ...state,
        player: {},
      };

    default:
      return state;
  }
};
