import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import theme from 'styled-theming';
import { darken, rem } from 'polished';

const ButtonStyled = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${props => (props.type === 'primary' ? (
    theme('mode', { light: props.color, dark: props.color })
  ) : 'transparent')};
  color: ${props => (props.type === 'primary' ? (
    theme('mode', { light: '#fff', dark: '#fff' })
  ) : '#aaadb1')};
  border: 1px solid;
  border-color: ${props => (props.type === 'primary' ? (
    theme('mode', { light: props.color, dark: props.color })
  ) : (
    theme('mode', { light: '#aaadb1', dark: '#aaadb1' })
  ))};
  border-radius: 24px;
  width: ${props => (props.fullWidth ? '100%' : 'auto')};
  cursor: pointer;
  font-size: ${rem('18px')};
  padding: 10px ${props => rem(`${props.offset}px`)};
  outline: none;
  transition: all 0.25s;

  &::-moz-focus-inner {
    border: 0;
  }

  &:hover {
    background: ${props => (props.type === 'primary' ? props.color : '#fff')};

    border-color: ${props => (props.type === 'primary' ? props.color : (
    theme('mode', { light: darken(0.1, '#aaadb1'), dark: darken(0.1, '#aaadb1') })
  ))};
    color: ${props => (props.type === 'primary' ? (
    theme('mode', { light: '#fff', dark: '#fff' })
  ) : darken(0.1, '#717171'))};

    box-shadow: ${props => (props.type === 'primary' ? (
    `0 0 30px ${props.color}`
  ) : '0')}
  }

  &:disabled {
    background: transparent;
    border-color: #aaadb1;
    color: #aaadb1;
    cursor: default;
  }
`;

class Button extends React.Component {
  static defaultProps = {
    type: 'default',
    onClick: null,
    fullWidth: false,
    color: '#1769ff',
    offset: 20,
  };

  static propTypes = {
    children: PropTypes.node.isRequired,
    type: PropTypes.string,
    onClick: PropTypes.func,
    fullWidth: PropTypes.bool,
    color: PropTypes.string,
    offset: PropTypes.number,
  };

  render() {
    return (
      <ButtonStyled {...this.props}>
        {this.props.children}
      </ButtonStyled>
    );
  }
}

export default Button;
