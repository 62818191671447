import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Loc } from 'redux-react-i18n';
import Button from 'components/mediabay-ui/components/Button';
import isUzb from '../utils/checkCountry';

const Header = styled.h3`
  margin: 10px 0 20px;
  font-size: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Row = styled.p`
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  border-bottom: 1.5px dotted #555;
  padding: 3px 2px;
  margin: 10px 0;
`;

const UserDropdown = props => (
  <React.Fragment>
    <div style={{ width: 250, padding: 15 }}>
      <Header>
        {props.profileData.email}
      </Header>
      <Row>
        <Loc locKey="id" />
        <span>
          {props.profileData.id}
        </span>
      </Row>
      {isUzb() && (
        <React.Fragment>
          <Row>
            <Loc locKey="balance" />
            <span>
              {(Intl
              && Intl.NumberFormat('ru-RU', {
                style: 'currency',
                currency: 'UZS',
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              }).format(props.profileData.balance))
              || props.profileData.balance}
            </span>
          </Row>
          <Row>
            <Loc locKey="expired" />
            <span>
              {
                props.profileData.vouchers.length ? (
                  moment(props.profileData.vouchers[0].expiredDate).format('DD.MM.YYYY')
                ) : (
                  <span>
                    <Loc locKey="notActiveVoucher" />
                  </span>
                )
              }
            </span>
          </Row>
        </React.Fragment>
      )}
    </div>
    <div
      style={{
        padding: '20px 25px',
        background: 'rgba(54,63,76,0.65)',
      }}
    >
      {isUzb() ? (
        <Link to="/profile/services" style={{ display: 'block', cursor: 'pointer' }} tabIndex={-1}>
          <Button type="primary" fullWidth>
            <Loc locKey="profileData" />
          </Button>
        </Link>
      ) : (
        <Link to="/profile/settings" style={{ display: 'block', cursor: 'pointer' }} tabIndex={-1}>
          <Button type="primary" fullWidth>
            <Loc locKey="profileData" />
          </Button>
        </Link>
      )}

      <Button
        onClick={() => {
          props.userSignOut();
          setTimeout(() => { window.location = '/'; }, 500);
        }}
        fullWidth
        style={{ marginTop: 15 }}
      >
        <Loc locKey="logOut" />
      </Button>
    </div>
  </React.Fragment>
);

UserDropdown.propTypes = {
  // Handlers
  userSignOut: PropTypes.func.isRequired,

  // Data
  profileData: PropTypes.shape({
    id: PropTypes.number,
    email: PropTypes.string,
    name: PropTypes.string,
    balance: PropTypes.number,
    vouchers: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
};

export default UserDropdown;
